<template>
  <div id="app">
    <Header></Header>
    <div class="content">
      <router-view/>
    </div> <!-- content -->
    <Footer></Footer>
  </div> <!-- app -->
</template>

<script>
  import Header from './Common/Header.vue';
  import Footer from './Common/Footer.vue';

  export default {
    name: 'App',
    components: {
      Header,
      Footer,
    },
  }
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
/*  text-align: center; */
  /*  color: #2c3e50; */
  width: 100%;
}

.content {
  /* margin-left: 5em; */
  width: 100%;
}

#nav {
  padding: 9em;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
