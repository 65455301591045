<template>
  <b-container class="footer">
    <p>Darren Hale, &copy; 2020</p>
  </b-container> <!-- footer -->
</template>

<script>
  export default {
    name: 'Footer',
    components: {
    },
  }
</script>

<style scoped>
  .footer {
    text-align: center;
  }

  p {
    font-size: 10pt;
    color: gray;
  }
</style>
