<template>
  <b-container class="menu">
    <router-link to="/">Home</router-link>
    <a href="https://darrensnotebook.blogspot.com/">Blog</a>
    <router-link to="/contact">Contact</router-link>
  </b-container> <!-- menu -->
</template>

<script>
  export default {
    name: 'Menu',
    components: {
    },
  }
</script>

<style scoped>
  .menu {
  }

  .menu a {
    color: green;
    font-weight: bold;
    padding: 0.5em 0.5em 0.5em 0.5em;
    text-decoration: none;
  }

  .menu a:hover {
    color: #954e0c;
  }
  
</style>
