<template>
  <b-container class="about">
    <h1>About Me</h1>
    <p>
I'm a maker.  I create with software.  I enjoy making new things that make people's lives easier, allow businesses to do things that weren't previously possible, or that showcase how software can change how we think about our world.
    </p>
    <p>
I have used numerous tools and technologies over the course of my career.  Right now, my main programming language is C#.  I'm proficient with SQL and Javascript.  I'm learning F# and VueJS.  In the past, I've used Java, Visual Basic, Perl, PHP, and Ruby to build large-scale applications or small utilities.
    </p>
    <p>
Most of my software experience is developing applications that are capable of scaling easily with a growing business.  I've done this by focusing on application design and maintainability.  I've used physical data centers as well as a major cloud provider as deployment targets for these applications.
    </p>
    <p>
I'm constantly looking for ways to improve how I create code and how I can use software to change the world around me.  You can keep up with how I do this on my <a href="https://darrensnotebook.blogspot.com/">blog</a>.
    </p>
    <p>
If you would like to explore ways I can help you leverage software to transform your business, <router-link to="/contact">reach out to me.</router-link>
    </p>
  </b-container> <!-- about -->
</template>

<script>
  export default {
    name: 'About',
    components: {
    },
  }
</script>

<style scoped>
  .about {
    max-width: 800px;
  }
  
  h1 {
    font-size: 18pt;
  }

  p {
    font-family: Time;
    font-size: 18pt;
    padding: 1em 1em 1em 1em;
  }
</style>
