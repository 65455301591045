<template>
  <b-container class="site-header">
    <b-container class="titles">
      <h1>Darren Hale</h1>
      <h3>Changing the World with Software.</h3>
    </b-container> <!-- titles -->
    <b-container class="header-menu">
      <Menu></Menu>
    </b-container> <!-- header-menu -->
  </b-container> <!-- site-header -->
</template>

<script>
  import Menu from './Menu.vue';
  
  export default {
    name: 'Header',
    components: {
      Menu,
    },
  }
</script>

<style scoped>
  .site-header {
   /* background-image: url('../../public/matrix.jpg'); */
    background-color: white;
    background-size: cover;
    /* padding: 0.5em 0.5em 0.5em 10em; */
    /* margin: -10px -10px auto; */
    width: 100%; /* 100vw;*/
    display: inline-flex;
  }

  h1 {
    font-size: 36pt;
    color: #954e0c;
    margin-bottom: 0.25em;
  }

  h3 {
    font-size: 12pt;
    margin-bottom: 2em;
  }

  .header-menu {
    /* margin: 0em 0em 0em 20em; */
  }
  
</style>
