<template>
  <div class="contact">
    <h1>Contact Me</h1>
    <p>
      I'm pretty easy to contact through social media.
    </p>
    <p>
      <ul>
        <li>I'm on Twitter: <a href="https://twitter.com/darrenhale">@darrenhale</a></li>
        <li>I'm on Github: <a href="https://github.com/haled">https://github.com/haled</a></li>
        <li>I'm on LinkedIn: <a href="https://www.linkedin.com/in/darrenehale/">https://www.linkedin.com/in/darrenehale/</a></li>
      </ul>
    </p>
    <p>
      Or you can email me at <a href="mailto:darren@halesoftwaresolutions.com">darren@halesoftwaresolutions.com</a>.
    </p>
  </div> <!-- contact -->
</template>

<script>
  export default {
    name: 'Contact',
    components: {
    },
  }
</script>

<style scoped>
  p {
    font-family: Times;
    font-size: 16pt;
  }
  
  h1 {
    font-size: 18pt;
  }
  
  ul {
    list-style-type: none;
    padding: 0 0 0 0;
  }

  li {
    padding: 1em 1em 1em 1em;
  }
  
</style>
