<template>
  <b-container class="main-site">
    <About></About>
  </b-container>
</template>

<script>
import About from './About.vue';

export default {
  name: 'Home',
  components: {
    About,
  }
}
</script>

<style scoped>
  .main-site {
    /* background-color: white; */
  }
</style>
